.en #rate-Q1-5:checked ~ .footer .text::before
{
  content:"Love it!!!";
}
.en #rate-Q1-4:checked ~ .footer .text::before
{
  content:"Awesome!";
}
.en #rate-Q1-3:checked ~ .footer .text::before
{
  content:"Neutral";
}
.en #rate-Q1-2:checked ~ .footer .text::before
{
  content:"I don't like it";
}
.en #rate-Q1-1:checked ~ .footer .text::before
{
  content:"Hate it!"; 
}


/* aligne qustion3 content */
.en .alignment {
  text-align: left;
}

