
.ar #rate-Q1-5:checked ~ .footer .text::before {
  content:"رائع";
}

.ar #rate-Q1-4:checked ~ .footer .text::before
{
  content:"جيد";
}
.ar #rate-Q1-3:checked ~ .footer .text::before
{
  content:"مقبول";
}
.ar #rate-Q1-2:checked ~ .footer .text::before
{
  content:"غير جيد";
}
.ar #rate-Q1-1:checked ~ .footer .text::before
{
  content:"سيء"; 
}
  

  /* comment in the arabic version  */
 .ar textarea {
    text-align: right;
  }


  /* aligne qustion3 content */
  .ar .alignment {
    text-align: right;
  }
  
/* checkbox postion */
  .ar .checkbox-container {
    direction: rtl;
  }

 
  